<template>
	<BaseInput
		v-bind="forbind"
		v-on="$listeners"
	>
		<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
	</BaseInput>
</template>

<script>
import Validators from '../Validators';
import BaseInput from './BaseInput';
import IModelMixin from "../IModelMixin";

export default {
	mixins: [IModelMixin],

	components:{
		BaseInput
	},

	props:{
		name:String,
		type:String,
	},

	computed:{

		fld: function(){
			return this.imodel.fields[this.name] || {};
		},

		forbind: function(){
			const type = this.type ? this.type : this.fld.inputType;

			const forbind = {
				label: this.fld.label,
				type,
				...this.$attrs,
			};

			if (!this.$attrs.disabled && this.fld.rules) {
				if (!forbind.rules) forbind.rules = [];
				for (const rule in this.fld.rules) {
					const params = this.fld.rules[rule];
					const fn = Validators[rule];
					if (fn) {
						forbind.rules.push(fn.call(Validators, ...params));
					}
				}
			}

			switch (type) {

				case 'decimal':

					if (this.fld.int != null && !forbind.int) {
						forbind.int = this.fld.int;
					}
					if (this.fld.dec != null && !forbind.dec) {
						forbind.dec = this.fld.dec;
					}
					break;

				case 'textarea':
					if (parseInt(this.fld.height)>0) {
						forbind.height = this.fld.height;
					}
					break;

				default:
					break;
			}

			// which props to pass from the model field to the input
			const modelProps = [
				//'options', handled separately
				'multiple',
			];

			if (!this.$attrs.disabled) {
				modelProps.push(...[
					'required',
					'pattern',
					'minlength',
					'maxlength',
					'min',
					'max',
					'minstring',
					'maxstring',
					'mincount',
					'maxcount',
				]);
			}

			for (const p of modelProps) {
				if (this.fld[p] && !forbind[p]) {
					forbind[p] = this.fld[p];
				}
			}

			if (this.$attrs.options) {
				forbind.options = this.$attrs.options;
			}
			else if (this.fld.options) {
				forbind.options = this.fld.options;
			}

			return forbind;
		},
	},

}
</script>