<template>
	<v-container fluid v-if="imodel.loaded">
		<please-select-utility>

			<v-row dense>
				<v-col>
					<v-sheet rounded elevation="2" class="pa-2 d-flex flex-wrap">

						<div>
							<base-input
								v-model="pismoId" label="ID"
								clearable outlined hide-details dense flat
								size="6"
							/>
						</div>

						<v-divider vertical class="mx-2" />

						<div>
							<model-input :imodel="imodel" name="n_uvedomitelni_pisma.up_type" v-model="upType"
								clearable outlined hide-details dense flat
								size="15"
							/>
						</div>

						<v-divider vertical class="mx-2" />

						<div>
							<model-input :imodel="imodel" name="n_uvedomitelni_pisma.delivery_type" v-model="deliveryType"
								clearable outlined hide-details dense flat
								size="15"
							/>
						</div>

						<v-divider vertical class="mx-2" />

						<div>
							<base-input v-model="isExpired"
								label="Влязло в сила"
								clearable outlined hide-details dense flat
								size="15"
								type="select"
								:options="[
									{text:'Да',value:'1'},
									{text:'Не',value:'0'},
								]"
							/>
						</div>

						<v-divider vertical class="mx-2" />

						<div>
							<base-input v-model="isProcessed"
								label="Обработено"
								clearable outlined hide-details dense flat
								size="15"
								type="select"
								:options="[
									{text:'Да',value:'1'},
									{text:'Не',value:'0'},
								]"
							/>
						</div>

						<v-divider vertical class="mx-2" />

						<div>
							<base-input v-model="isFixed"
								label="Приключено"
								clearable outlined hide-details dense flat
								size="15"
								type="select"
								:options="[
									{text:'Да',value:'1'},
									{text:'Не',value:'0'},
								]"
							/>
						</div>

						<v-divider style="flex: 0 0 100%;" class="my-2" />

						<div>
							<station-select-all
								label="Аб. Станция"
								v-model="stationId"
								clearable outlined hide-details dense flat
								class="flex-grow-1"
							/>
						</div>

						<v-divider vertical class="mx-2" />

						<div>
							<base-input v-model="deviceTypeCode"
								label="Тип уред"
								clearable outlined hide-details dense flat
								type="select"
								:options="deviceTypeOptions"
							/>
						</div>

						<v-divider vertical class="mx-2" />

						<div>
							<model-input :imodel="imodel" name="n_uvedomitelni_pisma.created_by" v-model="createdBy"
								label="Създадено от"
								clearable outlined hide-details dense flat
								size="15"
							/>
						</div>

						<v-divider vertical class="mx-2" />

						<div>
							<model-input :imodel="imodel" name="n_uvedomitelni_pisma.created_time" type="date"
								v-model="createdTimeLeft" label="След"
								clearable outlined hide-details dense flat
								size="8"
							/>
						</div>

						<div>
							<model-input :imodel="imodel" name="n_uvedomitelni_pisma.created_time" type="date"
								v-model="createdTimeRight" label="Преди"
								clearable outlined hide-details dense flat
								size="8"
								class="ml-2"
							/>
						</div>

					</v-sheet>
				</v-col>
			</v-row>

			<list
				ref="list"
				:imodel="imodel"
				:params="listParams"
				:headers="headers"
				hidden-cols="n_uvedomitelni_pisma.utility_code,n_uvedomitelni_pisma.station_id,n_uvedomitelni_pisma.imot_id"
				hide-delete
				@edit="onEdit"
				item-key="n_uvedomitelni_pisma.id"
			>
				<template #select-actions>
					<download-btn
						:rest-service="doDownload"
						small
					/>
				</template>
				<template #item.n_uvedomitelni_pisma.file="{item}">
					<MF :value="item['n_uvedomitelni_pisma.file']" show-size />
				</template>
				<template #item.n_uvedomitelni_pisma.file_receipt="{item}">
					<MF :value="item['n_uvedomitelni_pisma.file_receipt']" show-size />
				</template>
				<template #item.cnt_processed="{item}">
					<span class="green--text">{{ item['cnt_processed'] }}</span>
				</template>
				<template #item.cnt_not_processed="{item}">
					<span class="red--text">{{ item['cnt_not_processed'] }}</span>
				</template>
				<template #item.cnt_fixed="{item}">
					<span class="green--text">{{ item['cnt_fixed'] }}</span>
				</template>
				<template #item.cnt_not_fixed="{item}">
					<span class="red--text">{{ item['cnt_not_fixed'] }}</span>
				</template>
				<template #right-actions="{item}">
					<imot-details-link
						target-blank
						:utility="selectedUtility"
						:session="item['session_id']"
						:station="item['n_uvedomitelni_pisma.station_id']"
						:imot="item['n_uvedomitelni_pisma.imot_id']"
					/>
				</template>
			</list>

			<UPEditPopup
				ref="edit"
				:id="editId"
				@reload="onReload"
				no-activator
			/>

		</please-select-utility>
	</v-container>
</template>

<script>
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility.vue";
import StationSelectAll from "@/views/stations/StationSelectAll.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import ModelInput from "@/ittijs/Inputs/ModelInput.vue";
import BaseInput from "@/ittijs/Inputs/BaseInput.vue";
import List from "@/ittijs/List.vue";
import MF from "@/ittijs/components/MF.vue";
import ImotDetailsLink from "@/ittijs/components/ImotDetailsLink.vue";
import UPEditPopup from "@/ittijs/components/UvedomitelniPisma/UPEditPopup.vue";
import DownloadBtn from "@/ittijs/components/DownloadBtn.vue";
import {DeviceTypeGroups, DeviceTypeGroupTypes, DeviceTypeLabels} from "@/ittijs/Nomenclatures";
import ITTIModel from "@/ittijs/ITTIModel";

const Model = class extends ITTIModel {
	constructor() {
		super('UvedomitelniPismaSearch');
	}
	downloadAll(utility, ids){
		return this.fetch('downloadAll', {utility, ids});
	}
}

const model = ITTIModelProvider.getModel(Model);

export default {
	components: {
		DownloadBtn,
		UPEditPopup,
		ImotDetailsLink,
		MF,
		List,
		ModelInput,
		BaseInput,
		StationSelectAll,
		PleaseSelectUtility,
	},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
		}),
	],
	props: [
		'utility',
	],
	computed: {
		listParams(){
			return {
				utility              : this.selectedUtility,
				stationId            : this.stationId,
				createdBy            : this.createdBy,
				createdTimeLeft      : this.createdTimeLeft,
				createdTimeRight     : this.createdTimeRight,
				upType               : this.upType,
				deliveryType         : this.deliveryType,
				isExpired            : this.isExpired,
				isProcessed          : this.isProcessed,
				isFixed              : this.isFixed,
				deviceTypeCode       : this.deviceTypeCode,
				pismoId              : this.pismoId,
			};
		},
		headers: () => ([
			...Object.values(model.getListHeaders([
				'n_uvedomitelni_pisma.id',
			])),
			{text: 'Номер станция',     value: 'station_N',       skipInList: true, sortable: false},
			{text: 'Номер имот',        value: 'imot_N',          skipInList: true, sortable: false},
			{text: 'Име',               value: 'client_name',     skipInList: true, sortable: false},
			{text: 'Адрес',             value: 'address',         skipInList: true, sortable: false},
			...Object.values(model.getListHeaders([
				'n_uvedomitelni_pisma.up_type',
				'n_uvedomitelni_pisma.created_time',
				'n_uvedomitelni_pisma.created_by',
				'n_uvedomitelni_pisma.received',
				'n_uvedomitelni_pisma.expiration_days',
				'n_uvedomitelni_pisma.file',
				'n_uvedomitelni_pisma.file_receipt',
				'n_uvedomitelni_pisma.delivery_type',
				'cnt_processed',
				'cnt_not_processed',
				'cnt_fixed',
				'cnt_not_fixed',
			])),
		]),
		deviceTypeOptions(){
			return DeviceTypeGroups.get(DeviceTypeGroupTypes.Physical).map(code => ({
				text: DeviceTypeLabels.get(code),
				value: code,
			}));
		},
	},
	data(){
		return {
			imodel: model,
			stationId: null,
			createdBy: null,
			createdTimeLeft: null,
			createdTimeRight: null,
			upType: null,
			deliveryType: null,
			isExpired: null,
			isProcessed: null,
			isFixed: null,
			deviceTypeCode: null,
			pismoId: null,
			data: null,
			editId: null,
		}
	},
	methods: {
		onEdit(item){
			this.editId = item['n_uvedomitelni_pisma.id'];
			this.$refs.edit.open();
		},
		onReload(){
			this.$refs.list.refresh();
		},
		doDownload(){
			return this.imodel.downloadAll(
				this.selectedUtility,
				this.$refs.list.getSelectedKeys().map(key => key['n_uvedomitelni_pisma.id'])
			);
		},
	},
}
</script>